import * as React from "react"


//On importe les composants
// import Formulaire from './formulaire'


//On importre le CSS


//On importe les SVG
import PhoneBlack from '../images/phone-call-black.svg'



export default function Adresse() {


    return (
        <div className="BackGrey" id="contact">

            <div className="AdresseMain">

                <div className="AdresseContainer">

                    <div className="AdresseMainCol">
                        <h4>DEBRABANT AVOCAT</h4>

                        <ul>
                            <li>6, Parvis Notre Dame</li>
                            <li>62200 BOULOGNE-SUR-MER</li>
                        </ul>

                        <p><a href="tel:+33321101526"><img src={PhoneBlack} alt="Téléphone" width="20px" />03 21 10 15 26</a></p>

                        <p><a href="mailto:debrabant.avocat@outlook.fr">debrabant.avocat@outlook.fr</a></p>



                    </div>

                    <div className="AdresseMainCol">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.658810972447!2d1.6123782517076544!3d50.72626867523199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47dc2c48c4eef88d%3A0xc192554b01a36ff0!2s6%20Parvis%20Notre%20Dame%2C%2062200%20Boulogne-sur-Mer!5e0!3m2!1sfr!2sfr!4v1619464397949!5m2!1sfr!2sfr" title="carte" width="90%" height="340px" style={{border:0}} loading="lazy"></iframe>
                    </div>

                </div>

                <div className="AdresseForm">

                    {/* <Formulaire /> */}

                </div>


            </div>

        </div>
    )
}