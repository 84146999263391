import * as React from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async'


//On importe les composants
import Header from '../components/header'
import Footer from '../components/footer'
// import Formulaire from '../components/formulaire'
import Adresse from '../components/adresse'

//On importe les images
import Favicon from '../images/favicon.jpg'
import Info from '../images/info.svg'

//On importe le CSS
import '../css/main.scss'
import '../css/footer.scss'
import '../css/adresse.scss'
import '../css/article.scss'
import '../css/bandeau-accueil.scss'
import '../css/bandeau-contact.scss'
import '../css/bandeau-page.scss'
import '../css/domaines.scss'
import '../css/formulaire.scss'
import '../css/header.scss'
import '../css/presentation.scss'
import '../css/resultats.scss'
import '../css/tableau.scss'

//On importe les SVG
// import Scroll from '../images/scroll.svg'


export default function Contact() {



    return (
        <HelmetProvider>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Contacter le cabinet Debrabant Avocat</title>
                <meta name="description" content="Envoyer un message ou obtenez les informations de contact du cabinet Debrabant Avocat." />
                <html lang="fr" />
                <link rel="icon" href={Favicon} />


            </Helmet>

            <Header />

            <div className="BackGrey">
                <div className="BandeauMain">

                    <div className="BandeauPageContainer">

                        <h1>CONTACT</h1>

                    </div>


                </div>

            </div>

            <div className="MainContact">

                <p align="center">
                <img src={Info} alt="information" width="20px" />

                    Le 1er rendez-vous d'une durée de 45min. est facturé 96€ T.T.C.
                    </p>
            </div>


            {/* <Formulaire /> */}


            <Adresse />



            <Footer />


        </HelmetProvider >
    )
}
